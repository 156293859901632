/* eslint-disable prettier/prettier */
import { useEffect, useState, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { getStore } from 'store/store';
import { ToastContainer, toast } from 'react-toastify';
import { useQuery } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { AppLayout } from 'layouts/AppLayout';
import { AuthLayout2 } from 'layouts/AuthLayout2';
import { PromoLayout } from 'layouts/PromoLayout';
import { HomePageLayout } from 'layouts/HomePageLayout';
import { HammerSpinLayout } from 'layouts/HammerSpinLayout';
import NotificationProvider from 'components/notification';
import { getSocialConfiguration } from 'api/apiRequest';
// import { LandingPage } from 'pages/landing/LandingPage';
import { PromoPage } from 'pages/landing/PromoPage';
import { PromoPage2 } from 'pages/landing/PromoPage2';
import { PromoPage3 } from 'pages/landing/PromoPage3';
import { GamicPromoPage } from 'pages/landing/GamicPromoPage';
import { DailyBonusPage } from 'components/daily-bonus';
import CircularLoader from 'components/loader/CircularLoader';
import { MerchantLogin } from 'pages/landing/MerchantLogin';
import { BeePromoPage } from 'pages/landing/BeePromoPage';
import { JayFundPromoPage } from 'pages/landing/influencers/JayFund';
import { JoysucexPromoPage } from 'pages/landing/influencers/JoySucex';
import { LovebirdPromoPage } from 'pages/landing/influencers/LoveBird';
import { MobileLogin } from 'pages/landing/MobileLogin';
import { ShaggiPromoPage } from 'pages/landing/influencers/Shaggi';
import { NewHomePage } from 'pages/landing/NewHomePage';
import { GamesPage } from 'pages/landing/GamesPage';
import { MTNLandingPage } from 'pages/landing/MTNLandingPage';
import { VasPhoneRegistrationPage } from 'pages/auth/vas/VasPhoneRegistrationPage';
import { VasEnterPasswordPage } from 'pages/auth/vas/VasEnterPasswordPage';
import { VasCreatePasswordPage } from 'pages/auth/vas/VasCreatePasswordPage';
import { OnboardingPage } from 'pages/onboarding';
import { LoadingPage } from 'pages/landing/LoadingPage';
import { I18nextProvider, useTranslation } from 'react-i18next';
import ChatModal from 'pages/chat/chatModal';

// Lazy-loaded components
const Prizes = lazy(() =>
	import('pages/prizes/Prizes').then((module) => ({
		default: module.Prizes,
	}))
);
const LearnMore = lazy(() =>
	import('pages/learnmore/index').then((module) => ({
		default: module.LearnMore,
	}))
);
// const Marketplace = lazy(() =>
// 	import('pages/marketplace/Index').then((module) => ({
// 		default: module.Marketplace,
// 	}))
// );
const MarketplaceDetails = lazy(() =>
	import('pages/marketplace/details').then((module) => ({
		default: module.default,
	}))
);
const Store = lazy(() =>
	import('pages/store/StorePage').then((module) => ({
		default: module.default,
	}))
);
const StoreItemDetail = lazy(() =>
	import('pages/store/StoreItemDetail').then((module) => ({
		default: module.default,
	}))
);

const AccountDeposit = lazy(() =>
	import('pages/account/deposit').then((module) => ({
		default: module.default,
	}))
);
const DepositFaqs = lazy(() =>
	import('pages/account/faqs').then((module) => ({
		default: module.default,
	}))
);
const GetStarted = lazy(() =>
	import('pages/auth/GetStarted').then((module) => ({
		default: module.GetStarted,
	}))
);
const LoginPage = lazy(() =>
	import('pages/auth/LoginPage').then((module) => ({
		default: module.LoginPage,
	}))
);

const CompleteRegistration = lazy(() =>
	import('pages/auth/CompleteRegistration').then((module) => ({
		default: module.CompleteRegistration,
	}))
);

const AccountPage = lazy(() =>
	import('pages/account/AccountPage').then((module) => ({
		default: module.AccountPage,
	}))
);
const AccountSettings = lazy(() =>
	import('pages/accountSettings').then((module) => ({
		default: module.AccountSettings,
	}))
);
const AccountWithdrawal = lazy(() =>
	import('pages/account/withdrawal').then((module) => ({
		default: module.AccountWithdrawal,
	}))
);
const AccountWithdrawalHistory = lazy(() =>
	import('pages/account/withdrawalHistory').then((module) => ({
		default: module.AccountWithdrawalHistory,
	}))
);
const UpdatePassword = lazy(() =>
	import('pages/accountSettings/UpdatePassword').then((module) => ({
		default: module.UpdatePassword,
	}))
);
const UpdateEmail = lazy(() =>
	import('pages/accountSettings/UpdateEmail').then((module) => ({
		default: module.UpdateEmail,
	}))
);
const UpdateEmailVerification = lazy(() =>
	import('pages/accountSettings/UpdateEmailVerification').then((module) => ({
		default: module.UpdateEmailVerification,
	}))
);
const UpdatePhoneNumber = lazy(() =>
	import('pages/accountSettings/UpdatePhoneNumber').then((module) => ({
		default: module.UpdatePhoneNumber,
	}))
);
const TwoFactorAuthentication = lazy(() =>
	import('pages/accountSettings/TwoFactorAuthentication').then((module) => ({
		default: module.TwoFactorAuthentication,
	}))
);
const EditProfile = lazy(() =>
	import('pages/accountSettings/EditProfile').then((module) => ({
		default: module.EditProfile,
	}))
);
const KycVerification = lazy(() =>
	import('pages/accountSettings/KycVerification').then((module) => ({
		default: module.KycVerification,
	}))
);
const KycVerificationSteps = lazy(() =>
	import('pages/accountSettings/KycVerificationSteps').then((module) => ({
		default: module.KycVerificationSteps,
	}))
);
const KycVerificationPending = lazy(() =>
	import('pages/accountSettings/KycVerificationPending').then((module) => ({
		default: module.KycVerificationPending,
	}))
);
const TransactionsHistory = lazy(() =>
	import('pages/accountSettings/History/TransactionsHistory').then(
		(module) => ({
			default: module.TransactionsHistory,
		})
	)
);
const HistoryDepositDetails = lazy(() =>
	import('pages/accountSettings/History/HistoryDepositDetails').then(
		(module) => ({
			default: module.HistoryDepositDetails,
		})
	)
);
const HistoryWithrawalDetails = lazy(() =>
	import('pages/accountSettings/History/HistoryWithdrawalDetails').then(
		(module) => ({
			default: module.HistoryWithrawalDetails,
		})
	)
);
const MarketplaceCollections = lazy(() =>
	import('pages/marketplace/collections').then((module) => ({
		default: module.default,
	}))
);
const MarketplaceIceCollections = lazy(() =>
	import('pages/marketplace/collectionsIce').then((module) => ({
		default: module.default,
	}))
);
const MarketplaceBackgroundCollections = lazy(() =>
	import('pages/marketplace/collectionsBackground').then((module) => ({
		default: module.default,
	}))
);
const StoreCollections = lazy(() =>
	import('pages/store/StoreCollections').then((module) => ({
		default: module.default,
	}))
);
const DailyLeaderboard = lazy(() =>
	import('pages/prizes/DailyLeaderboard').then((module) => ({
		default: module.DailyLeaderboard,
	}))
);
const WeeklyLeaderboard = lazy(() =>
	import('pages/prizes/WeeklyLeaderboard').then((module) => ({
		default: module.WeeklyLeaderboard,
	}))
);
const LeaderboardFaqs = lazy(() =>
	import('pages/prizes/LeaderboardFaqs').then((module) => ({
		default: module.default,
	}))
);
const Rewards = lazy(() =>
	import('pages/prizes/Rewards').then((module) => ({
		default: module.Rewards,
	}))
);
const ClaimReward = lazy(() =>
	import('pages/prizes/ClaimReward').then((module) => ({
		default: module.ClaimReward,
	}))
);
const AddShippingDetails = lazy(() =>
	import('pages/prizes/AddShippingDetails').then((module) => ({
		default: module.AddShippingDetails,
	}))
);
const WeeklyDraw = lazy(() =>
	import('pages/prizes/WeeklyDraw').then((module) => ({
		default: module.WeeklyDraw,
	}))
);
const FreeSpinGame = lazy(() =>
	import('pages/hammerSpin/FreeGame').then((module) => ({
		default: module.FreeSpinGame,
	}))
);
const PaidSpinGame = lazy(() =>
	import('pages/hammerSpin/PaidGamePlay').then((module) => ({
		default: module.PaidSpinGame,
	}))
);
const DemoSpinGame = lazy(() =>
	import('pages/hammerSpin/DemoGamePlay').then((module) => ({
		default: module.DemoSpinGame,
	}))
);
const RecentWinners = lazy(() =>
	import('components/recentWinner/RecentWinners').then((module) => ({
		default: module.RecentWinners,
	}))
);
const Jackpot = lazy(() =>
	import('pages/jackpot').then((module) => ({
		default: module.Jackpot,
	}))
);
const JackpotDetails = lazy(() =>
	import('pages/jackpot/details').then((module) => ({
		default: module.default,
	}))
);
const JackpotList = lazy(() =>
	import('pages/jackpot/list').then((module) => ({
		default: module.JackpotList,
	}))
);

const JackpotWinners = lazy(() =>
	import('pages/jackpot/winners').then((module) => ({
		default: module.JackpotWinners,
	}))
);
const JackpotHowItWorks = lazy(() =>
	import('pages/jackpot/how-it-works').then((module) => ({
		default: module.default,
	}))
);

const JackpotFaqs = lazy(() =>
	import('pages/jackpot/faqs').then((module) => ({
		default: module.default,
	}))
);

const Referrals = lazy(() =>
	import('pages/referrals/Index').then((module) => ({
		default: module.default,
	}))
);
const ReferralFaqs = lazy(() =>
	import('pages/referrals/faqs').then((module) => ({
		default: module.default,
	}))
);
const PaymentDetails = lazy(() =>
	import('pages/accountSettings/PaymentDetails').then((module) => ({
		default: module.PaymentDetails,
	}))
);
const OdogwuLandingPage = lazy(() =>
	import('pages/odogwu').then((module) => ({
		default: module.OdogwuLandingPage,
	}))
);
const ScoresAndStats = lazy(() =>
	import('components/scoresAndStats/ScoresAndStats').then((module) => ({
		default: module.ScoresAndStats,
	}))
);

const Unsubscribe = lazy(() =>
	import('pages/email-subscription/EmailSubscriptions').then((module) => ({
		default: module.Unsubscribe,
	}))
);

const Subscriptions = lazy(() =>
	import('pages/subscriptions/SubscriptionPage').then((module) => ({
		default: module.Subscriptions,
	}))
);

const ManageSubscription = lazy(() =>
	import('pages/subscriptions/ManageSubscription').then((module) => ({
		default: module.ManageSubscription,
	}))
);

const VerifySubcriptionPhone = lazy(() =>
	import('components/subscription/VerifySubcriptionPhone').then((module) => ({
		default: module.VerifySubcriptionPhone,
	}))
);

const GamicDashboard = lazy(() =>
	import('pages/gamic/GamicDashboard').then((module) => ({
		default: module.GamicDashboard,
	}))
);

// const Winners = lazy(() =>
// 	import('pages/gamic/Winners').then((module) => ({
// 		default: module.Winners,
// 	}))
// );

const TopPlayers = lazy(() =>
	import('pages/gamic/TopPlayers').then((module) => ({
		default: module.TopPlayers,
	}))
);

const HowToParticipate = lazy(() =>
	import('pages/gamic/HowToParticipate').then((module) => ({
		default: module.HowToParticipate,
	}))
);

const DailyMissions = lazy(() =>
	import('pages/landing/DailyMissions').then((module) => ({
		default: module.DailyMissions,
	}))
);

const SelectLanguage = lazy(() =>
	import('pages/onboarding/SelectLanguage').then((module) => ({
		default: module.SelectLanguage,
	}))
);

const ComingSoon = lazy(() =>
	import('pages/tournaments/ComingSoon').then((module) => ({
		default: module.ComingSoon,
	}))
);

const Tournaments = lazy(() =>
	import('pages/tournaments/Tournaments').then((module) => ({
		default: module.Tournaments,
	}))
);

const User = lazy(() =>
	import('pages/User').then((module) => ({
		default: module.User,
	}))
);

const SingleTournament = lazy(() =>
	import('pages/tournaments/SingleTournament').then((module) => ({
		default: module.SingleTournament,
	}))
);

const FinishedTournament = lazy(() =>
	import('pages/tournaments/FinishedTournament').then((module) => ({
		default: module.FinishedTournament,
	}))
);

const TournamentLeaderboard = lazy(() =>
	import('pages/tournaments/TournamentLeaderboard').then((module) => ({
		default: module.TournamentLeaderboard,
	}))
);

function App() {
	const [dimensions, setDimensions] = useState({
		height: 0,
	});
	const [themeState, setThemeState] = useState('dark');
	const toggleTheme = (mode: string) => setThemeState(mode);

	const [loadingComplete, setLoadingComplete] = useState(false);
	const [isFirstTimeUSer, setIsFirstTimeUser] = useState('false');

	useEffect(() => {
		const firstTimeUser = localStorage.getItem('isFirstTimeUser');
		if (firstTimeUser !== null) {
			setIsFirstTimeUser(firstTimeUser);
		} else setIsFirstTimeUser('true');
	}, []);

	// Disable context menu - blocks right-click context menus.
	// document.addEventListener(
	// 	'contextmenu',
	// 	function (e) {
	// 		e.preventDefault();
	// 	},
	// 	false
	// );

	// Disable text selection start - prevents text selection entirely.
	// document.addEventListener('selectstart', function (e) {
	// 	e.preventDefault();
	// });

	useEffect(() => {
		const getTheme = localStorage.getItem('hammer-theme');
		if (getTheme === 'dark') setThemeState('dark');
		else if (getTheme === 'light') setThemeState('light');
	}, []);

	useEffect(() => {
		if (themeState === 'dark') {
			localStorage.setItem('hammer-theme', 'dark');
			document.body.classList.add('dark-mode');
			document.body.classList.remove('light-mode');
		} else if (themeState === 'light') {
			localStorage.setItem('hammer-theme', 'light');
			document.body.classList.add('light-mode');
			document.body.classList.remove('dark-mode');
		}
	}, [themeState]);

	useEffect(() => {
		const handleNetworkChange = () => {
			const onlineStatus = navigator.onLine ? 'online' : 'offline';
			if (onlineStatus == 'offline') {
				toast.error(`You're now offline`, {
					position: toast.POSITION.TOP_RIGHT,
					theme: 'colored',
					autoClose: 3000,
				});
			} else {
				toast.success(`You're back online`, {
					position: toast.POSITION.TOP_RIGHT,
					theme: 'colored',
					autoClose: 3000,
				});
			}
		};

		window.addEventListener('online', handleNetworkChange);
		window.addEventListener('offline', handleNetworkChange);

		return () => {
			window.removeEventListener('online', handleNetworkChange);
			window.removeEventListener('offline', handleNetworkChange);
		};
	}, []);

	// use this to get the initial window.innerHeight dimensions
	useEffect(() => {
		let divHeight = 0;
		if (window.innerHeight) {
			divHeight = window.innerHeight;
		}
		setDimensions({
			height: Number(divHeight),
		});
	}, [window.innerHeight]);

	// use this to get window.innerHeight dimensions on screen resize
	useEffect(() => {
		function handleResize() {
			setDimensions({
				height: window.innerHeight,
			});
		}
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	const { data } = useQuery(
		['socialConfiguration'],
		async () => await getSocialConfiguration(),
		{
			// onSuccess: (res: any) => {
			// 	setClientId(res?.data?.googleClientId);
			// },
			retry: 0,
		}
	);

	const LoadingComponent = () => {
		return (
			<div>
				<CircularLoader />
			</div>
		);
	};

	const { i18n } = useTranslation('common');

	const [isLanguageChange, setIsLanguageChange] = useState(false);

	useEffect(() => {
		const userLang = localStorage.getItem('game-user-lang');
		// console.log('user lang>>> ', userLang);
		if (userLang !== null) {
			i18n.changeLanguage(userLang);
		} else {
			i18n.changeLanguage('en');
		}
		console.log('Language change');
	}, [i18n, isLanguageChange]);

	useEffect(() => {
		if (window?.Telegram?.WebApp) {
			const webApp = window.Telegram.WebApp;
			webApp.ready();

			if (!webApp.isExpanded) {
				webApp.expand();
			}
		}
	});

	const handleLoginSuccess = () => {
		// Add a 5-second timeout before proceeding
		setTimeout(() => {
			setLoadingComplete(true); // Proceed only after 5 seconds
		}, 4000);
	};

	return (
		<GoogleOAuthProvider clientId={data?.data?.googleClientId}>
			<I18nextProvider i18n={i18n}>
				<TonConnectUIProvider
					manifestUrl={`https://api-staging.hammergames.io/hooks/bots/bots/tonconnect-manifest.json`}
				>
					<Provider store={getStore}>
						<NotificationProvider>
							<div
								className={`w-screen bg-primary`}
								id="app"
								style={{ height: `${dimensions.height}px` }}
							>
								<div className="bg-primary">
									<ToastContainer
										position="top-right"
										autoClose={4000}
										hideProgressBar
										newestOnTop
									/>
									<BrowserRouter>
										<Routes>
											<Route
												path="/auth/get-started"
												element={
													<AuthLayout2
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<GetStarted />
														</Suspense>
													</AuthLayout2>
												}
											/>
											<Route
												path="/auth/login"
												element={
													<AuthLayout2
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<LoginPage />
														</Suspense>
													</AuthLayout2>
												}
											/>
											<Route
												path="/auth/vas-phone-registration"
												element={
													<AuthLayout2
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<VasPhoneRegistrationPage />
														</Suspense>
													</AuthLayout2>
												}
											/>
											<Route
												path="/auth/vas-enter-password"
												element={
													<AuthLayout2
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<VasEnterPasswordPage />
														</Suspense>
													</AuthLayout2>
												}
											/>
											<Route
												path="/auth/vas-create-password"
												element={
													<AuthLayout2
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<VasCreatePasswordPage />
														</Suspense>
													</AuthLayout2>
												}
											/>
											<Route
												path="/auth/complete-registration"
												element={
													<Suspense fallback={<LoadingComponent />}>
														<CompleteRegistration />
													</Suspense>
												}
											/>

											{/* Other Routes */}
											{!loadingComplete ? (
												<Route
													path="/"
													element={
														<LoadingPage onLoginSuccess={handleLoginSuccess} />
													}
												/>
											) : (
												<Route
													path="/"
													element={
														isFirstTimeUSer !== 'false' ? (
															<OnboardingPage />
														) : (
															<HomePageLayout
																theme={themeState}
																toggleTheme={toggleTheme}
															>
																<NewHomePage />
															</HomePageLayout>
														)
													}
												/>
											)}
											<Route
												index
												path="/promo"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<PromoPage />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/megawin"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<PromoPage3 />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/referrer"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<PromoPage2 />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/gamic-promo"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<GamicPromoPage />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/bee-promo"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<BeePromoPage />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/jayfund"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<JayFundPromoPage />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/joysucex"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<JoysucexPromoPage />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/loveb3rd"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<LovebirdPromoPage />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/MTN"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<MTNLandingPage />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/shaggi"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<ShaggiPromoPage />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/MTN"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<MTNLandingPage />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/merchant-login"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<MerchantLogin />
													</PromoLayout>
												}
											/>
											<Route
												index
												path="/mobile-login"
												element={
													<PromoLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<MobileLogin />
													</PromoLayout>
												}
											/>
											<Route
												path="/play"
												element={
													<HomePageLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<NewHomePage />
													</HomePageLayout>
												}
											/>
											<Route
												path="/games"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<GamesPage />
													</AppLayout>
												}
											/>
											<Route
												path="/prizes"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<Prizes />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												path="/prizes/leaderboard"
												element={
													// <AppLayout
													// 	theme={themeState}
													// 	toggleTheme={toggleTheme}
													// >
													<Suspense fallback={<LoadingComponent />}>
														<DailyLeaderboard />
													</Suspense>
													// </AppLayout>
												}
											/>
											<Route
												path="/prizes/leaderboard/faqs"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<LeaderboardFaqs />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												path="/prizes/weekly-leaderboard"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<WeeklyLeaderboard />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												path="/prizes/rewards"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<Rewards />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												path="/prizes/rewards/claim"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<ClaimReward />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												path="/prizes/rewards/shipping"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<AddShippingDetails />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												path="/prizes/weekly-draws"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<WeeklyDraw />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<AccountPage />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/withdraw"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<AccountWithdrawal />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/withdraw/history"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<AccountWithdrawalHistory />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<AccountSettings />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/referrals"
												element={
													// <AppLayout
													// 	theme={themeState}
													// 	toggleTheme={toggleTheme}
													// >
													<Suspense fallback={<LoadingComponent />}>
														<Referrals />
													</Suspense>
													// </AppLayout>
												}
											/>
											<Route
												index
												path="/account/referrals/faqs"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<ReferralFaqs />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings/language"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<SelectLanguage
																isLanguageChange={isLanguageChange}
																setIsLanguageChange={setIsLanguageChange}
																source="settings"
															/>
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/marketplace/collections"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<MarketplaceCollections />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/store/collections"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<StoreCollections />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/store/item-detail"
												element={
													// <AppLayout
													// 	theme={themeState}
													// 	toggleTheme={toggleTheme}
													// >
													<Suspense fallback={<LoadingComponent />}>
														<StoreItemDetail />
													</Suspense>
													// </AppLayout>
												}
											/>
											<Route
												index
												path="/marketplace/collections-background"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<MarketplaceBackgroundCollections />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/marketplace/collections-ice"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<MarketplaceIceCollections />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/deposit"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<AccountDeposit />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/deposit/faqs"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<DepositFaqs />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/faqs"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<DepositFaqs />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings/update-email"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<UpdateEmail />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings/update-email-verification"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<UpdateEmailVerification />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings/update-mobile"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<UpdatePhoneNumber />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings/update-password"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<UpdatePassword />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings/two-factor"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<TwoFactorAuthentication />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings/edit-profile"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<EditProfile />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings/kyc-verification"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<KycVerification />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings/kyc-verification/steps"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<KycVerificationSteps />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings/kyc-verification/pending"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<KycVerificationPending />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/history"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<TransactionsHistory />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/history/jackpots"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<TransactionsHistory />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/history/games"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<TransactionsHistory />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/history/transactions"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<TransactionsHistory />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/history/all-transactions"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<TransactionsHistory />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/history/deposits/:id"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<HistoryDepositDetails />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/history/withdrawals/:id"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<HistoryWithrawalDetails />
														</Suspense>
													</AppLayout>
												}
											/>
											{/* <Route
												index
												path="/marketplace"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<Marketplace />
														</Suspense>
													</AppLayout>
												}
											/> */}
											<Route
												index
												path="/store"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<Store />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/marketplace/:id"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<MarketplaceDetails />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/recent-winners"
												element={
													// <AppLayout theme={themeState} toggleTheme={toggleTheme}>
													<Suspense fallback={<LoadingComponent />}>
														<RecentWinners />
													</Suspense>
													// </AppLayout>
												}
											/>
											<Route
												index
												path="/jackpot"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
														showSidebar={false}
													>
														<Suspense fallback={<LoadingComponent />}>
															<Jackpot />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/jackpot/:id"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
														showSidebar={false}
													>
														<Suspense fallback={<LoadingComponent />}>
															<JackpotDetails />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/jackpots/:id"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
														showSidebar={false}
													>
														<Suspense fallback={<LoadingComponent />}>
															<JackpotList />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/jackpot/winners"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
														showSidebar={false}
													>
														<Suspense fallback={<LoadingComponent />}>
															<JackpotWinners />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/jackpot/how-it-works"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
														showSidebar={false}
													>
														<Suspense fallback={<LoadingComponent />}>
															<JackpotHowItWorks />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/jackpot/faqs"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<JackpotFaqs />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/chatModal"
												element={
													<HammerSpinLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<ChatModal />
														</Suspense>
													</HammerSpinLayout>
												}
											/>
											<Route
												index
												path="/spin"
												element={
													<HammerSpinLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<PaidSpinGame />
														</Suspense>
													</HammerSpinLayout>
												}
											/>
											<Route
												index
												path="/hammer-spin/free-spin"
												element={
													<HammerSpinLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<FreeSpinGame />
														</Suspense>
													</HammerSpinLayout>
												}
											/>
											<Route
												index
												path="/hammer-spin"
												element={
													<HammerSpinLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<DemoSpinGame />
														</Suspense>
													</HammerSpinLayout>
												}
											/>

											{/* <Route path="/odogwu-hunter" element={<OdogwuHunter />} /> */}
											<Route
												index
												path="/odogwu-hunter"
												element={
													<Suspense fallback={<LoadingComponent />}>
														<OdogwuLandingPage />
													</Suspense>
												}
											/>
											<Route
												index
												path="/account/settings/payment-details"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<PaymentDetails />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/daily-bonus"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<DailyBonusPage />
													</AppLayout>
												}
											/>
											<Route
												index
												path="/scores-and-stats"
												element={
													// <AppLayout
													// 	theme={themeState}
													// 	toggleTheme={toggleTheme}
													// >
													<Suspense fallback={<LoadingComponent />}>
														<ScoresAndStats />
													</Suspense>
													// </AppLayout>
												}
											/>
											<Route
												index
												path="/learnmore"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<LearnMore />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/account/settings/manage-email-subscriptions"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<Unsubscribe />
														</Suspense>
													</AppLayout>
												}
											/>

											<Route
												index
												path="/subscriptions"
												element={
													// <AppLayout theme={themeState} toggleTheme={toggleTheme}>
													<Suspense fallback={<LoadingComponent />}>
														<Subscriptions />
													</Suspense>
													// </AppLayout>
												}
											/>
											<Route
												index
												path="/manage-subscription"
												element={
													// <AppLayout theme={themeState} toggleTheme={toggleTheme}>
													<Suspense fallback={<LoadingComponent />}>
														<ManageSubscription />
													</Suspense>
													// </AppLayout>
												}
											/>
											<Route
												index
												path="/subscribe/verify-phone"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<VerifySubcriptionPhone />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/trustwallet-leaderboard"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<GamicDashboard />
														</Suspense>
													</AppLayout>
												}
											/>
											{/* <Route
												path="/trustwallet-leaderboard/winners"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<Winners />
														</Suspense>
													</AppLayout>
												}
											/> */}
											<Route
												index
												path="/trustwallet-leaderboard/:id"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<TopPlayers />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/trustwallet-leaderboard/how-to-participate"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<HowToParticipate />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/daily-missions"
												element={
													<AppLayout
														theme={themeState}
														toggleTheme={toggleTheme}
													>
														<Suspense fallback={<LoadingComponent />}>
															<DailyMissions />
														</Suspense>
													</AppLayout>
												}
											/>
											<Route
												index
												path="/tournaments/coming-soon"
												element={
													<Suspense fallback={<LoadingComponent />}>
														<ComingSoon />
													</Suspense>
												}
											/>
											<Route
												index
												path="/user-deets"
												element={
													<Suspense fallback={<LoadingComponent />}>
														<User />
													</Suspense>
												}
											/>

											<Route
												index
												path="/tournaments"
												element={
													<Suspense fallback={<LoadingComponent />}>
														<Tournaments />
													</Suspense>
												}
											/>
											<Route
												index
												path="/single-tournament"
												element={
													// <AppLayout
													// 	theme={themeState}
													// 	toggleTheme={toggleTheme}
													// >
													<Suspense fallback={<LoadingComponent />}>
														<SingleTournament />
													</Suspense>
													// </AppLayout>
												}
											/>
											<Route
												index
												path="/tournament-result"
												element={
													// <AppLayout
													// 	theme={themeState}
													// 	toggleTheme={toggleTheme}
													// >
													<Suspense fallback={<LoadingComponent />}>
														<FinishedTournament />
													</Suspense>
													// </AppLayout>
												}
											/>
											<Route
												index
												path="/tournament-leaderboard"
												element={
													<Suspense fallback={<LoadingComponent />}>
														<TournamentLeaderboard />
													</Suspense>
												}
											/>
										</Routes>
									</BrowserRouter>
								</div>
							</div>
						</NotificationProvider>
					</Provider>
				</TonConnectUIProvider>
			</I18nextProvider>
		</GoogleOAuthProvider>
	);
}

export default App;
